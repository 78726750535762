import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React from "react";
import Author from "../components/author";
import BlogPosts from "../components/blog-posts";
import Container from "../components/container";
import Header from "../components/header";

export default function Index({ location, data: { allPosts, site, blog, author } }) {
  return (
    <Container>
      <HelmetDatoCms seo={blog.seo} favicon={site.favicon} />
      <Header currentPath={location.pathname} />
      <Author author={{ name: author.name, picture: author.picture.gatsbyImageData, summary: author.summary?.value }} />
      {<BlogPosts posts={allPosts.nodes} title="Didn't fit in a Tweet AKA /blog ✍" />}
    </Container>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    blog: datoCmsBlog {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          large: gatsbyImageData(width: 1500)
          small: gatsbyImageData(width: 760)
        }
        author {
          name
          picture {
            gatsbyImageData(
              layout: FIXED
              width: 48
              height: 48
            )
          }
        }
      }
    }
    author: datoCmsAuthor{
      name
       picture {
        gatsbyImageData(
          layout: FIXED
          width: 200
          height: 200
        )
      }
      summary{
        value
      }
    }
  }
`;
